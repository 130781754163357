$(function () {
  $('.js-docs-slider').slick({
    infinite: true,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  });

  $('.js-menu-open').click((e) => {
    e.preventDefault();
    $('.menu').addClass('is-active');
  });

  $('.js-menu-close').click((e) => {
    e.preventDefault();
    $('.menu').removeClass('is-active');
  });

  $('.js-team-slider').slick({
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    responsive: [{
      breakpoint: 768,
      settings: "unslick"
    }]
  });

  $('.js-articles-slider').slick({
    mobileFirst: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
    arrows: false,
    adaptiveHeight: true,
    responsive: [{
      breakpoint: 768,
      settings: "unslick"
    }]
  });

  $('.nav__submenu-toggle').click(function(e) {
    e.preventDefault();
    e.stopPropagation();
    $(this).parents('.has-submenu').toggleClass('is-opened').siblings().removeClass('is-opened');
  });

  $('.js-accordion-toggle').click(function () {
    $(this)
      .parent()
      .toggleClass('is-active')
      .siblings()
      .removeClass('is-active');
  });

  $('.js-materials-slider').slick({
    slidesToShow: 4,
    slidesToScroll: 4,
    arrows: true,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  });

  $('.js-news-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 3,
    arrows: true,
    dots: false,
    infinite: false,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2
        }
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
          dots: true
        }
      }
    ]
  });

  $('.events-tabs__nav button').click(function (e) {
    e.preventDefault();
    if ($(this).hasClass('is-active')) return;

    const target = $($(this).attr('href'));
    $(this).addClass('is-active').siblings().removeClass('is-active');
    target.addClass('is-active').siblings().removeClass('is-active');
  });

  $('.js-gallery-main').slick({
    slidesToShow: 1,
    arrows: true,
    fade: true,
    responsive: [
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          dots: true
        }
      }
    ]
  });

  $('.js-gallery-nav').slick({
    slidesToShow: 7,
    asNavFor: '.js-gallery-main',
    swipeToSlide: true,
    dots: false,
    arrows: false,
    focusOnSelect: true,
  });

  initScrollSidebar();
  $(window).resize(() => {initScrollSidebar()});
});

const initScrollSidebar = () => {
  $.each($('.scroll__content'), (k, v) => {
    const parent = $(v).parents('.scroll');
    parent.removeClass('has-scroll');

    if ($(v).get(0).scrollHeight > $(v).get(0).clientHeight) {
      parent.addClass('has-scroll');
      parent.find('.scroll__btn').click((e) => {
        e.preventDefault();
        parent.find('.scroll__content').get(0).scrollBy(0, 100);
      });
    }
  });
};